<template>
  <v-container fluid>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <div class="d-flex mb-3">
      <h2>Sources</h2>
      <v-spacer />
      <v-btn class="ml-2" color="success">New Source</v-btn>
    </div>
    <div ref="table"></div>
    <MNote
      :text="[
        'Each team leader setup own integration with instruction provided when add new source',
      ]"
    ></MNote>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[tabulator]
import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
//EOC
//BOC:[faker]
import { faker } from "@faker-js/faker";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Leads",
      to: { name: "PageServiceDashboard", params: { serviceKey: "lead" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Sources",
      to: { name: "MockupLeadSourceBrowse" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    var data = [];
    for (let i = 1; i <= 100; i++) {
      data.push({
        id: i,
        source: ["ClickFunnel", "Facebook", "Zoom Webinar"][Math.round(Math.random() * 2)],
        sourceUuid: faker.string.uuid(),
        business: ["TaxPOD","商学院","TPDIY"][Math.round(Math.random() * 2)],
        team: ["CM","TaxPOD Sales Team", "TaxPOD Telemarketing"][Math.round(Math.random() * 2)],
        leadCount: Math.round(Math.random() * 1000),
        status: ["Open","Closed"][Math.round(Math.random() * 1)],
      });
    }
    this.tabulator = new Tabulator(this.$refs.table, {
      layout: "fitColumns",
      pagination: true,
      paginationSize: 10,
      data: data, //set initial table data
      columns: [
        { title: "Source", field: "source" },
        { title: "Source ID", field: "sourceUuid" },
        { title: "Business", field: "business" },
        { title: "Team", field: "team" },
        { title: "Status", field: "status" },
        { title: "Leads", field: "leadCount" },
      ],
    });
  },
  methods: {
    //
  },
};
</script>